export default function formatCurrencyRange(
  minValue: number | null | undefined,
  maxValue: number | null | undefined
): string {
  // Helper function to round up and format the value
  const formatValue = (value: number) => {
    if (isNaN(value)) return ""; // Check if the value is NaN
    const roundedValue = Math.ceil(value);
    if (roundedValue >= 1000000) {
      return `$${(roundedValue / 1000000).toFixed(3)}M`;
    } else {
      return `$${roundedValue.toLocaleString("en-SG")}`;
    }
  };

  // Handle different cases based on the provided values
  if (minValue === 0 && maxValue === 0) {
    return "Sold out";
  } else if (minValue === 1 && maxValue === 1) {
    return "Unreleased";
  } else if (
    (minValue == null && maxValue === 0) ||
    (maxValue == null && minValue === 0)
  ) {
    return ""; // Return empty string if min is null and max is 0, or min is 0 and max is null
  } else if (minValue == null || minValue === 0 || minValue === maxValue) {
    return formatValue(maxValue!); // Only display max value if min value is null, undefined, or 0
  } else if (maxValue == null) {
    return formatValue(minValue!); // Only display min value if max value is null or undefined
  } else {
    const formattedMin = formatValue(minValue);
    const formattedMax = formatValue(maxValue);
    return `${formattedMin} - ${formattedMax}`; // Return the formatted range
  }
}
